@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,600');

body, html { 
  overflow: hidden; 
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: url("./img/blurry.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  z-index: -1;
}

h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  z-index: 1;
}

.clicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20%;
  width: 40%;

  border-radius: 15px;
  background: rgb(39, 35, 52);
  box-shadow: 10px 10px 0px #0000009c;

  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;


}

.active {
  color:rgba(255, 255, 255, 0.362);
  
}

.dont-lower {
  text-transform: none;
}

/* settings */

.second-input {
  width: 5rem;
  height: 1.5rem;
  border-radius: 5px;
  border: none;
  margin-right: .8rem;

}

.enter-number {
  background-color: rgb(39, 35, 52);
  padding: 1rem;
  font-family: "Montserrat", sans-serif;
  border-radius: 10px;
  box-shadow: 5px 5px 0px #0000009c;

  width: 12rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;

  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  z-index: 1;
}

.seconds-span {
  margin-left: .8rem;
}

/* stats */

.stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  z-index: 1;

}

.stat {
  background-color: rgb(39, 35, 52);
  padding: 1rem;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;

  border-radius: 10px;
  box-shadow: 5px 5px 0px #0000009c;

  width: 7rem;

}

.gradient {
  /* height: 100%;
  width: 100%; */

  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg,#fa7268 0,#c62368);
  -webkit-background-clip: text;
  background-clip: text;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}

/* icons */


.icon-links {
  text-align: center;
  display: block;
  border: none;
  width:fit-content;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding:0;
  z-index: 1;
}

.icon-links li {
  padding: 10px;
  display: inline-block;
  color: rgba(255, 255, 255, 0.649);
  font-size: 20px;

  transition: ease-in-out 0.5s;

}

.icon-links li:hover {
  transform: scale(1.2);
  transition: ease-in-out 0.5s;
}

a, a:visited {
  color: rgba(255, 255, 255, 0.663);
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  /* background-color: #000; */
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #ca6594;
  animation: animate 25s linear infinite;
  bottom: -150px;
  z-index: 2;
  /* background-color: #000; */

}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}